<template>
  <div class="SaleDetails">

    <div class="SaleDetails-title">
      产品介绍
    </div>

    <div class="SaleDetails-info">
      <el-row>
        <el-col :span="6">
          <p>产品名称：{{ data.productName }}</p>
        </el-col>
        <el-col :span="6">
          <p>销售数量：{{ data.personCount }}</p>
        </el-col>
        <el-col :span="6">
          <p>产品类别：{{ data.productCategory ? $store.state.category.filter(item => { return item.dictKey == data.productCategory })[0].dictValue : null }}</p>
        </el-col>
        <el-col :span="6">
          <p>产品价格：{{ '￥' + data.price }}</p>
        </el-col>
        <el-col :span="24">
          <p>产品介绍：{{ data.productDesc }}</p>
        </el-col>
      </el-row>
    </div>

    <div class="SaleDetails-title">
      用户信息
    </div>

    <div class="SaleDetails-list">
      <el-table
          :data="data.buyList"
          style="width: 100%">
        <el-table-column
            prop="buyPerson"
            align="center"
            label="购买人姓名">
        </el-table-column>
        <el-table-column
            prop="buyPhone"
            align="center"
            label="购买人手机号">
        </el-table-column>
        <el-table-column
            prop="cardNo"
            align="center"
            label="订单/实体卡号">
        </el-table-column>
        <el-table-column
            prop="amount"
            align="center"
            label="实际购买价格">
        </el-table-column>
        <el-table-column
          prop="buyDate"
          align="center"
          label="购买时间">
        </el-table-column>
      </el-table>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="form.pageNum"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="form.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>


  </div>
</template>

<script>
export default {
  name: "SaleDetails",
  data() {
    return {
      data: {
        buyList: []
      },
      form: {
        pageNum: 1,
        pageSize: 10,
        productId: this.$route.query.id,
      },
      total: 0,
    }
  },
  created() {
    this.get_details(this.form);
  },
  methods: {
    get_details(y) {
      this.$get("sale/detail",y)
        .then(res => {
          if(res) {
            console.log(res);

            this.total= res.data.personCount;
            this.data= res.data;

          }
        })
    },
    handleSizeChange(e) {
      // this.form.pageNum= 1;
      this.form.pageSize= e;
      this.get_list(this.form);
    },
    handleCurrentChange(e) {
      this.form.pageNum= e;
      this.get_list(this.form);
    },
  }
}
</script>

<style lang="scss" scoped>
 @import "../../style/Sale/SaleDetails";
</style>
